import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
export var isDebugMode = !environment.production || localStorage['showLogging'];
var noop = function () { return undefined; };
var ɵ0 = noop;
var ConsoleLoggerService = /** @class */ (function () {
    function ConsoleLoggerService() {
    }
    Object.defineProperty(ConsoleLoggerService.prototype, "info", {
        get: function () {
            if (isDebugMode) {
                return console.log.bind(console);
            }
            else {
                return noop;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConsoleLoggerService.prototype, "warn", {
        get: function () {
            if (isDebugMode) {
                return console.warn.bind(console);
            }
            else {
                return noop;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConsoleLoggerService.prototype, "error", {
        get: function () {
            if (isDebugMode) {
                return console.error.bind(console);
            }
            else {
                return noop;
            }
        },
        enumerable: true,
        configurable: true
    });
    ConsoleLoggerService.prototype.invokeConsoleMethod = function (type, args) {
        var logFn = function () { return console[type] || console.log || noop; };
        logFn.apply(console, [args]);
    };
    ConsoleLoggerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConsoleLoggerService_Factory() { return new ConsoleLoggerService(); }, token: ConsoleLoggerService, providedIn: "root" });
    return ConsoleLoggerService;
}());
export { ConsoleLoggerService };
export { ɵ0 };
