import { AuthService } from '@progbonus/auth/auth.service';
import { CurrentMarketService } from './market/current-market.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { LoggerService } from '@progbonus/logger/logger.service';
import { NavHelper, buildNavs } from 'app/navigation/navigation';
import { replaceAll } from '@progbonus/helpers/string.helpers';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../auth/auth.service";
import * as i2 from "./market/current-market.service";
import * as i3 from "../../@fuse/components/navigation/navigation.service";
import * as i4 from "@angular/router";
import * as i5 from "../logger/logger.service";
var MarketReportLinks = /** @class */ (function () {
    function MarketReportLinks(_base) {
        this._base = _base;
    }
    Object.defineProperty(MarketReportLinks.prototype, "self", {
        get: function () {
            return this._base + '/reports';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarketReportLinks.prototype, "customers", {
        get: function () {
            return this.self + "/customers";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarketReportLinks.prototype, "instagram", {
        get: function () {
            return this.self + "/instagram";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarketReportLinks.prototype, "main", {
        get: function () {
            return this.self + "/main";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarketReportLinks.prototype, "birthday", {
        get: function () {
            return this.self + "/birthday";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarketReportLinks.prototype, "registration", {
        get: function () {
            return this.self + "/registration";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarketReportLinks.prototype, "bonus", {
        get: function () {
            return this.self + "/bonus";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarketReportLinks.prototype, "purchases", {
        get: function () {
            return this.self + "/purchases";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarketReportLinks.prototype, "sms", {
        get: function () {
            return this.self + "/sms";
        },
        enumerable: true,
        configurable: true
    });
    return MarketReportLinks;
}());
export { MarketReportLinks };
var AppNavService = /** @class */ (function () {
    function AppNavService(_auth, _currentCompany, _fuseNavigationService, _router, _logger) {
        this._auth = _auth;
        this._currentCompany = _currentCompany;
        this._fuseNavigationService = _fuseNavigationService;
        this._router = _router;
        this._logger = _logger;
        this.emptyKey = 'empty';
        // Get default navigation
        this._navigation = [];
        // Register the navigation to the service
        this._fuseNavigationService.register(this.emptyKey, this._navigation);
        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation(this.emptyKey);
        this._init();
    }
    Object.defineProperty(AppNavService.prototype, "currentMarketKey", {
        get: function () {
            return this._currentMarketKey;
        },
        enumerable: true,
        configurable: true
    });
    AppNavService.prototype.start = function () {
        // dumpy
    };
    AppNavService.prototype._userWithoutMarket = function (user) {
        if (!user) {
            this._logger.info("navigation for null user can't be update");
            return;
        }
        var navKey = this._navKeyForRole ||
            NavHelper.getNavKeyForRoles(this._auth.roles);
        var navs = buildNavs(this._auth.roles);
        if (!this._navKeyForRole) {
            // Register the new navigation
            this._fuseNavigationService.register(navKey, navs);
        }
        // Set the current navigation
        this._fuseNavigationService.setCurrentNavigation(navKey);
        this._navKeyForRole = navKey;
        this._logger.info("App Navs has been initialized as " + navKey + " for", this._auth.roles);
    };
    AppNavService.prototype._init = function () {
        var _this = this;
        this._cached = {};
        this._navUpdateSub = combineLatest([
            this._auth.currentUser$,
            this._currentCompany.currentMarket$
        ]).subscribe(function (arr) {
            var user = arr[0];
            var market = arr[1];
            if (!user) {
                // Set the current navigation
                _this._fuseNavigationService.setCurrentNavigation(_this.emptyKey);
                return;
            }
            if (!market) {
                _this._userWithoutMarket(_this._auth.currentUserValue);
                return;
            }
            var roles = _this._auth.roles.slice();
            if (_this._auth.isArchitector ||
                _this._auth.isHero ||
                _this._auth.isFranchisee) {
                roles.push('owner');
            }
            console.log("MARKET FOR NAVS", market);
            var navs = buildNavs(roles, market);
            var key = "market-" + market.companyCode;
            _this._currentMarketKey = key;
            if (!_this._fuseNavigationService.getNavigation(key)) {
                _this._fuseNavigationService.register(key, navs);
            }
            _this._fuseNavigationService.setCurrentNavigation(key);
            _this._logger.info("App Navs has been initialized as " + key + " with market for", _this._auth.roles);
        });
    };
    AppNavService.prototype.clearMarket = function () {
        if (!this._auth.isOwnerOrSeller) {
            this._userWithoutMarket(this._auth.currentUserValue);
            this._cached = {};
        }
    };
    AppNavService.prototype.composeMarketNavForUser = function (marketId, args) {
        if (marketId === void 0) { marketId = null; }
        if (args === void 0) { args = []; }
        var url = this._urlOfMarket(marketId);
        if (!args.length) {
            return url;
        }
        for (var _i = 0, args_1 = args; _i < args_1.length; _i++) {
            var a = args_1[_i];
            url += "/" + replaceAll(a, '/', '');
        }
        return url;
    };
    AppNavService.prototype._urlOfMarket = function (marketId) {
        if (marketId === void 0) { marketId = null; }
        return this._auth.isOwnerOrSeller ? '/market' : "/markets/" + marketId;
    };
    AppNavService.prototype.market = function (marketId) {
        if (this._cached[marketId]) {
            return this._cached[marketId];
        }
        var url = this._urlOfMarket(marketId);
        var urls = {
            reports: new MarketReportLinks(url)
        };
        this._cached[marketId] = urls;
        return this._cached[marketId];
    };
    AppNavService.prototype.navigateToMarket = function (marketId, params, extras) {
        if (extras === void 0) { extras = null; }
        var url = this.composeMarketNavForUser(marketId, params);
        // this._logger.info(`>>> url has been built: ${url}`);
        this._router.navigate([url, extras || {}]);
    };
    AppNavService.prototype.navigate = function (url, extras) {
        // const navigationExtras: NavigationExtras = {
        //     queryParams: {
        //         firstname: 'Nic',
        //         lastname: 'Raboy'
        //     }
        // };
        if (extras === void 0) { extras = null; }
        this._router.navigate([url]);
    };
    AppNavService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppNavService_Factory() { return new AppNavService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.CurrentMarketService), i0.ɵɵinject(i3.FuseNavigationService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.LoggerService)); }, token: AppNavService, providedIn: "root" });
    return AppNavService;
}());
export { AppNavService };
