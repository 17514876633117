import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { mergeMap, switchMap, tap, finalize, filter, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoggerService } from '@progbonus/logger/logger.service';
import { BehaviorSubject, of, timer, combineLatest } from 'rxjs';
import { IAmService } from '@progbonus/services/iam.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { JwtHelperService } from '@auth0/angular-jwt';
import { tokenGetter } from 'app/app.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/fire/auth";
import * as i4 from "../services/iam.service";
import * as i5 from "@auth0/angular-jwt/src/jwthelper.service";
import * as i6 from "../logger/logger.service";
window.global = window;
var AuthService = /** @class */ (function () {
    function AuthService(_router, _http, _fireAuth, _iam, _jwtHelper, _logger) {
        var _this = this;
        this._router = _router;
        this._http = _http;
        this._fireAuth = _fireAuth;
        this._iam = _iam;
        this._jwtHelper = _jwtHelper;
        this._logger = _logger;
        this.loginPage = '/login';
        this.loginUniversal = '/login/universal';
        this.profilePage = '/user/profile';
        this.local_storage_key = {
            current_user: 'current_user',
            id_token: 'id_token',
            access_token: 'access_token',
            auth_result: 'auth_result',
            refresh_token: 'refresh_token'
        };
        this._getUserDataRequest$ = new BehaviorSubject(false);
        this.hasRole = function (role) { return _this.userHasRoles([role.trim()]); };
        this._idToken = localStorage.getItem('id_token');
        this._accessToken = tokenGetter();
        this._refreshToken = localStorage.getItem(this.local_storage_key.refresh_token);
        this.authData$ = new BehaviorSubject(null);
        this._getUserData$ = this._getUserDataRequest$
            .pipe(filter(function (x) { return x; }), switchMap(function (x) { return _this._getInfo(); }))
            .subscribe(function (x) {
            _this._logger.info("subscribed on user");
            _this.authData$.next({ user: x });
        });
        this.currentUser$ = this.authData$.pipe(tap(function (x) { return (_this._currUser = x ? x.user : null); }), map(function (x) { return (x ? x.user : null); }));
    }
    Object.defineProperty(AuthService.prototype, "isAuthenticated", {
        // private _isAuthenticated = new BehaviorSubject(false);
        // isAuthenticated$: Observable<boolean>;
        get: function () {
            return !this._jwtHelper.isTokenExpired(this.accessToken);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "currentUserValue", {
        get: function () {
            return this._currUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "userId", {
        get: function () {
            return this.currentUserValue ? this.currentUserValue.id : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "avatar", {
        get: function () {
            return 'assets/images/avatars/profile.jpg';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "email", {
        get: function () {
            return this.currentUserValue ? this.currentUserValue.email : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "displayName", {
        get: function () {
            var name = this.currentUserValue
                ? ((this.currentUserValue.firstName || '') + " " + (this.currentUserValue.lastName || '')).trim()
                : '';
            var role = this.currentUserValue
                ? this.currentUserValue.roles.some(function (x) { return x === 'cashier' || x === 'seller'; })
                    ? 'Продавец'
                    : null
                : null;
            return name || role;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "scopes", {
        get: function () {
            return this.currentUserValue ? this.currentUserValue.scope : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "roles", {
        get: function () {
            return this.currentUserValue && this.currentUserValue.roles
                ? this.currentUserValue.roles
                : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "expiresAt", {
        get: function () {
            return this._jwtHelper.getTokenExpirationDate(this.accessToken);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "isArchitector", {
        get: function () {
            return this.hasRole('architect');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "isHero", {
        get: function () {
            return this.hasRole('hero') || this.hasRole('manager');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "isSeller", {
        get: function () {
            return this.hasRole('cashier') || this.hasRole('seller');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "isOwner", {
        get: function () {
            return this.hasRole('owner');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "isOwnerOrSeller", {
        get: function () {
            return this.isOwner || this.isSeller;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "isFranchisee", {
        get: function () {
            return this.hasRole('franchisee');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "_localUser", {
        get: function () {
            return JSON.parse(localStorage.getItem('currentUser'));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "accessToken", {
        get: function () {
            return this._accessToken;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "idToken", {
        get: function () {
            return this._idToken;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "refreshToken", {
        get: function () {
            return this._refreshToken;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.authorize = function () {
    };
    AuthService.prototype.getProfile = function (cb) {
        if (!this._accessToken) {
            throw new Error('Access Token must exist to fetch profile');
        }
    };
    AuthService.prototype.handleAuthentication = function () {
        this._logger.info("handleAuthentication...");
        var isLoggedIn = this.isLoggedIn();
        if (!isLoggedIn) {
            this.authData$.next({ error: 'error' });
            var url = location.href.indexOf('/login/universal') > -1
                ? this.loginUniversal
                : this.loginPage;
            this._router.navigate([url]);
        }
    };
    AuthService.prototype._localLogin = function (authResult) {
        // Set the time that the access token will expire at
        var expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
        this._accessToken = authResult.accessToken;
        this._idToken = authResult.idToken;
        // localStorage.setItem('expires_at', expiresAt.toString());
        // if (this._isDebug) {
        localStorage.setItem('access_token', this.accessToken);
        localStorage.setItem('id_token', this.idToken);
        // }
    };
    AuthService.prototype.renewTokens = function (isUpdateUser) {
        if (isUpdateUser === void 0) { isUpdateUser = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var body;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        body = new URLSearchParams({
                            client_id: environment.identity.clientId,
                            grant_type: 'refresh_token',
                            refresh_token: this._refreshToken,
                            scope: 'market.api.debug market.api openid profile offline_access'
                        });
                        return [4 /*yield*/, this.getAccessTokenFor(body, isUpdateUser)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.scheduleRenewal = function () {
        var _this = this;
        if (!this.isAuthenticated) {
            return;
        }
        this.unscheduleRenewal();
        var expiresAt = this.expiresAt;
        var expiresIn$ = of(expiresAt.getTime()).pipe(mergeMap(function (expiresAt2) {
            var now = Date.now();
            var afterSec = expiresAt2 - now - 10 * 1000; // before 10 sec
            _this._logger.info(new Date().toISOString() + " Next token renew after " + afterSec / 1000 + " sec.");
            // Use timer to track delay until expiration
            // to run the refresh at the proper time
            return timer(Math.max(1, afterSec));
        }));
        // Once the delay time from above is
        // reached, get a new JWT and schedule
        // additional refreshes
        this.refreshSub = expiresIn$.subscribe(function () {
            _this.renewTokens();
            _this.scheduleRenewal();
        });
    };
    AuthService.prototype.unscheduleRenewal = function () {
        if (this.refreshSub) {
            this.refreshSub.unsubscribe();
        }
    };
    AuthService.prototype.logout = function () {
        // clear
        this.clear();
        // Sign out of Firebase
        this.loggedInFirebase = false;
        this._fireAuth.auth.signOut();
        // Go back to the home route
        this._router.navigate([this.loginPage]);
    };
    AuthService.prototype.clear = function () {
        this._accessToken = '';
        this._idToken = '';
        this.unscheduleRenewal();
        this.unscheduleFirebaseRenewal();
        localStorage.clear();
        this.authData$.next(null);
    };
    AuthService.prototype._getInfo = function () {
        var _this = this;
        this.isBusyToFetchUserData = true;
        return combineLatest([
            this._iam.getInfo(),
            this.getFirebaseToken()
        ]).pipe(tap(function (x) { return _this._logger.info("MY INFO", x); }), tap(function (x) {
            return localStorage.setItem('currentUser', JSON.stringify(x[0]));
        }), 
        // tap(x => this.currentUserSubject.next(x[0])),
        tap(function (x) {
            if (x[0]) {
                _this.scheduleRenewal();
            }
            if (x[1]) {
                _this._firebaseAuth(x[1]);
            }
        }), finalize(function () { return (_this.isBusyToFetchUserData = false); }), map(function (x) { return x[0]; }));
    };
    AuthService.prototype.userHasScopes = function (scopes) {
        var _this = this;
        return scopes.every(function (scope) { return _this.scopes.includes(scope); });
    };
    AuthService.prototype.userHasRoles = function (roles) {
        var _this = this;
        return roles.some(function (role) { return _this.roles.includes(role); });
    };
    AuthService.prototype.login = function (username, password, cb) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var body, promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                body = new URLSearchParams({
                    grant_type: 'password',
                    client_id: environment.identity.clientId,
                    username: username,
                    password: password,
                    scope: 'market.api.debug market.api openid profile offline_access'
                });
                promise = this.getAccessTokenFor(body, true);
                return [2 /*return*/, promise
                        .then(function (json) {
                        _this.handleAuthentication();
                        return Promise.resolve();
                    })
                        .catch(function (error) {
                        return Promise.reject(error);
                    })];
            });
        });
    };
    AuthService.prototype.getFirebaseToken = function () {
        // Prompt for login if no access token
        if (!this.isAuthenticated) {
            this.authorize();
        }
        return this._http.get(environment.progbonusApi + "auth/firebase", {
            headers: new HttpHeaders().set('Authorization', "Bearer " + this.accessToken)
        });
    };
    AuthService.prototype._firebaseAuth = function (tokenObj) {
        var _this = this;
        this._fireAuth.auth
            .signInWithCustomToken(tokenObj.firebaseToken)
            .then(function (res) {
            _this.loggedInFirebase = true;
            // Schedule token renewal
            _this.scheduleFirebaseRenewal();
            console.log('Successfully authenticated with Firebase!');
        })
            .catch(function (err) {
            var errorCode = err.code;
            var errorMessage = err.message;
            console.error(errorCode + " Could not log into Firebase: " + errorMessage);
            _this.loggedInFirebase = false;
        });
    };
    AuthService.prototype.scheduleFirebaseRenewal = function () {
        var _this = this;
        // If user isn't authenticated, check for Firebase subscription
        // and unsubscribe, then return (don't schedule renewal)
        if (!this.loggedInFirebase) {
            if (this.firebaseSub) {
                this.firebaseSub.unsubscribe();
            }
            return;
        }
        // Unsubscribe from previous expiration observable
        this.unscheduleFirebaseRenewal();
        // Create and subscribe to expiration observable
        // Custom Firebase tokens minted by Firebase
        // expire after 3600 seconds (1 hour)
        var expiresAt = new Date().getTime() + 3600 * 1000;
        var expiresIn$ = of(expiresAt).pipe(mergeMap(function (expires) {
            var now = Date.now();
            // Use timer to track delay until expiration
            // to run the refresh at the proper time
            return timer(Math.max(1, expires - now));
        }));
        this.refreshFirebaseSub = expiresIn$.subscribe(function () {
            console.log('Firebase token expired; fetching a new one');
            _this.getFirebaseToken();
        });
    };
    AuthService.prototype.unscheduleFirebaseRenewal = function () {
        if (this.refreshFirebaseSub) {
            this.refreshFirebaseSub.unsubscribe();
        }
    };
    AuthService.prototype.isLoggedIn = function () {
        var user = localStorage.getItem(this.local_storage_key.current_user);
        if (user) {
            return true;
        }
        return false;
    };
    AuthService.prototype.getAccessTokenFor = function (body, isLogin) {
        if (isLogin === void 0) { isLogin = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fetchPromise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                fetchPromise = fetch(environment.identity.idpAuthority + '/connect/token', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                        'Access-Control-Allow-Origin': 'true'
                    },
                    body: body
                });
                return [2 /*return*/, fetchPromise
                        .then(function (response) {
                        if (!response.ok) {
                            throw new Error(response.statusText);
                        }
                        return response.json();
                    })
                        .then(function (json) {
                        var data = _this.parseJwt(json.access_token);
                        data.roles = data.role.split(',').map(function (x) { return x.toLowerCase(); });
                        data.market = { companyId: data.tenant_id };
                        _this._accessToken = json.access_token;
                        _this._idToken = json.access_token;
                        _this._refreshToken = json.refresh_token;
                        localStorage.setItem(_this.local_storage_key.current_user, JSON.stringify(data));
                        localStorage.setItem(_this.local_storage_key.access_token, json.access_token);
                        localStorage.setItem(_this.local_storage_key.refresh_token, json.refresh_token);
                        localStorage.setItem(_this.local_storage_key.id_token, json.access_token);
                        localStorage.setItem(_this.local_storage_key.auth_result, JSON.stringify(data));
                        if (isLogin) {
                            _this._getUserDataRequest$.next(true);
                        }
                        return Promise.resolve();
                    })
                        .catch(function (error) {
                        return Promise.reject(error);
                    })];
            });
        });
    };
    AuthService.prototype.parseJwt = function (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64)
            .split('')
            .map(function (c) { return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2); })
            .join(''));
        return JSON.parse(jsonPayload);
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.AngularFireAuth), i0.ɵɵinject(i4.IAmService), i0.ɵɵinject(i5.JwtHelperService), i0.ɵɵinject(i6.LoggerService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
