import { environment } from 'environments/environment';
var BaseApiService = /** @class */ (function () {
    function BaseApiService(http) {
        this.http = http;
    }
    Object.defineProperty(BaseApiService.prototype, "baseUrl", {
        get: function () {
            return environment.progbonusApi;
        },
        enumerable: true,
        configurable: true
    });
    return BaseApiService;
}());
export { BaseApiService };
