import * as tslib_1 from "tslib";
import { BaseApiService } from './base-api.service';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from '@progbonus/logger/logger.service';
import { tap, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../logger/logger.service";
var IAmService = /** @class */ (function (_super) {
    tslib_1.__extends(IAmService, _super);
    function IAmService(http, _logger) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        _this._logger = _logger;
        _this._myUrl = _this.baseUrl + "api/users/iam";
        return _this;
    }
    IAmService.prototype.getInfo = function () {
        var _this = this;
        this._logger.info("Try get me...");
        return this.http.get(this._myUrl).pipe(tap(function (x) { return _this._logger.info("I am", x); }), catchError(function (error) {
            _this._logger.info("I am with error", error);
            return of(null);
        }));
    };
    IAmService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IAmService_Factory() { return new IAmService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoggerService)); }, token: IAmService, providedIn: "root" });
    return IAmService;
}(BaseApiService));
export { IAmService };
