import { AuthService } from '@progbonus/auth/auth.service';
import { LoggerService } from '@progbonus/logger/logger.service';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../auth/auth.service";
import * as i2 from "../logger/logger.service";
var DisplayService = /** @class */ (function () {
    function DisplayService(_auth, _logger) {
        var _this = this;
        this._auth = _auth;
        this._logger = _logger;
        this._auth.currentUser$
            .pipe(tap(function (u) { return _this._logger.info("Display for user:", u); }))
            .subscribe(function (u) {
            _this.avatar = 'assets/images/avatars/profile.jpg';
            if (!u) {
                _this.username = null;
                _this.email = null;
                return;
            }
            var username = u.username;
            var name = ((u.firstName || '') + " " + (u.lastName || '')).trim();
            var role = u.roles.some(function (x) { return x === 'seller' || x === 'cashier'; })
                ? 'Продавец'
                : u.roles.some(function (x) { return x === 'owner'; })
                    ? 'Владелец'
                    : null;
            _this.username = name
                ? name
                : username
                    ? username
                    : role
                        ? role
                        : null;
            _this.email = u.email;
        });
    }
    DisplayService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DisplayService_Factory() { return new DisplayService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.LoggerService)); }, token: DisplayService, providedIn: "root" });
    return DisplayService;
}());
export { DisplayService };
