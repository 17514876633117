import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
var FirestoreService = /** @class */ (function () {
    function FirestoreService(afs) {
        this.afs = afs;
    }
    /// *********
    /// Get a Reference
    /// *********
    FirestoreService.prototype.col = function (ref, queryFn) {
        return typeof ref === 'string'
            ? this.afs.collection(ref, queryFn)
            : ref;
    };
    FirestoreService.prototype.doc = function (ref) {
        return typeof ref === 'string' ? this.afs.doc(ref) : ref;
    };
    /// *********
    /// GET DATA
    /// *********
    FirestoreService.prototype.doc$ = function (ref) {
        return this.doc(ref)
            .snapshotChanges()
            .pipe(map(function (doc) { return doc.payload.data(); }));
    };
    FirestoreService.prototype.col$ = function (ref, queryFn) {
        return this.col(ref, queryFn)
            .snapshotChanges()
            .pipe(map(function (docs) { return docs.map(function (x) { return x.payload.doc.data(); }); }));
    };
    FirestoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FirestoreService_Factory() { return new FirestoreService(i0.ɵɵinject(i1.AngularFirestore)); }, token: FirestoreService, providedIn: "root" });
    return FirestoreService;
}());
export { FirestoreService };
