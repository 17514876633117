import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoggerService } from '@progbonus/logger/logger.service';
import { FirestoreService } from '../firestore.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../firestore.service";
import * as i3 from "../../logger/logger.service";
var CurrentMarketService = /** @class */ (function () {
    function CurrentMarketService(_http, _afs, _logger) {
        var _this = this;
        this._http = _http;
        this._afs = _afs;
        this._logger = _logger;
        this.onSellerStoreChanged = new Subject();
        this._currentMarketSubject = new BehaviorSubject(JSON.parse(localStorage.getItem(this._currentMarketKey)));
        this.currentMarket$ = this._currentMarketSubject.asObservable();
        this._firestoreCompany$ = this.currentMarket$.subscribe(function (x) {
            return x
                ? _this._afs
                    .doc$("companies/" + x.companyCode)
                    .subscribe(function (company) {
                    _this._logger.info("Company from firestore is", company);
                })
                : null;
        });
    }
    Object.defineProperty(CurrentMarketService.prototype, "_currentMarketKey", {
        get: function () {
            return 'currentMarket';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentMarketService.prototype, "_currentStoreKey", {
        get: function () {
            return 'currentStoreId';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentMarketService.prototype, "id", {
        get: function () {
            return this.currentMarketValue
                ? this.currentMarketValue.companyId
                : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentMarketService.prototype, "code", {
        get: function () {
            return this.currentMarketValue
                ? this.currentMarketValue.companyCode
                : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentMarketService.prototype, "tz", {
        get: function () {
            return this.currentMarketValue ? this.currentMarketValue.cityTz : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentMarketService.prototype, "now", {
        get: function () {
            return this.currentMarketValue ? new Date() : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentMarketService.prototype, "today", {
        get: function () {
            return this.now ? new Date(this.now.toDateString()) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentMarketService.prototype, "phoneCode", {
        get: function () {
            return this.currentMarketValue
                ? this.currentMarketValue.phoneCode
                : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentMarketService.prototype, "hasUtmSources", {
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentMarketService.prototype, "currentMarketValue", {
        get: function () {
            return this._currentMarketSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    CurrentMarketService.prototype.load = function (market) {
        if (this.id === market.companyId) {
            return;
        }
        this.clear();
        localStorage.setItem(this._currentMarketKey, JSON.stringify(market));
        this._currentMarketSubject.next(market);
    };
    Object.defineProperty(CurrentMarketService.prototype, "storeId", {
        get: function () {
            if (!this._storeId) {
                if (+localStorage[this._currentStoreKey]) {
                    this.setStoreId(+localStorage[this._currentStoreKey]);
                }
            }
            return this._storeId;
        },
        enumerable: true,
        configurable: true
    });
    CurrentMarketService.prototype.setStoreId = function (storeId) {
        localStorage[this._currentStoreKey] = storeId;
        this._storeId = storeId;
        this.onSellerStoreChanged.next(this.storeId);
    };
    CurrentMarketService.prototype.setStore = function (store) {
        this.setStoreId(store ? store.storeId : null);
    };
    CurrentMarketService.prototype.clear = function () {
        localStorage.removeItem(this._currentMarketKey);
        localStorage.removeItem(this._currentStoreKey);
        this._currentMarketSubject.next(null);
    };
    CurrentMarketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrentMarketService_Factory() { return new CurrentMarketService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FirestoreService), i0.ɵɵinject(i3.LoggerService)); }, token: CurrentMarketService, providedIn: "root" });
    return CurrentMarketService;
}());
export { CurrentMarketService };
