import * as i0 from "@angular/core";
var Logger = /** @class */ (function () {
    function Logger() {
    }
    return Logger;
}());
export { Logger };
var LoggerService = /** @class */ (function () {
    function LoggerService() {
    }
    LoggerService.prototype.invokeConsoleMethod = function (type, args) { };
    LoggerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggerService_Factory() { return new LoggerService(); }, token: LoggerService, providedIn: "root" });
    return LoggerService;
}());
export { LoggerService };
